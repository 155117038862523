.slick-dots {
    bottom:0px;
    top:0px;
    
}
.slick-thumb {
    
    
}
.slick-thumb li {
    display: list-item;
    
    right:88px;
    width:74px;
    height:58px;
}

.slick-next:before, .slick-prev:before {
    color: #000;
}

.slick-thumb li.slick-active img {
    border: 2px solid #77E600;
    filter: grayscale(0);
}

.slick-thumb li img {
    
    filter: grayscale(1);
}

.slick-center img {
    border: 2px solid #00bcd4;
}

.vertical-dots
{
    right:10px;
    list-style: none;
    display: block;
    position: absolute;
    top: 40%;
    margin-top: -10px;
    text-align: right;
}
.vertical-dots li
{
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.vertical-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.vertical-dots li button:hover,
.vertical-dots li button:focus
{
    outline: none;
}
.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before
{
    opacity: 1;
}
.vertical-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.vertical-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}